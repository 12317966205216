<template>
    <div class="hc13Box" style="text-align: left">
        <div class="hbBox1" :style="`background-image: url(${getAdvData(13).children[0].image});`">
            <div class="h1Box">
                <div class="hb1Left">
                    <p class="hl1">外贸独立站网站建设</p>
                    <p class="hl2">专注高询盘外贸独立网站建设7年轻松全球营销获客</p>
                    <div class="hl3">
                        <div class="hl3b">
                            <p class="h1t">3000+</p>
                            <p class="h1b">3000+外贸网站案例</p>
                        </div>
                        <div class="hl3b">
                            <p class="h1t">3000+</p>
                            <p class="h1b">3000+外贸网站案例</p>
                        </div>
                    </div>
                    <div class="hl4">
                        <img src="http://iv.vu818.com/img/xma14.png" alt="" class="hl4Img" />
                        <a :href="getAdvData(13).children[0].url" target="_blank" rel="noopener noreferrer">
                            <p class="hl4p">立即咨询 ></p>
                        </a>
                        <div class="h4Code">
                            <img :src="getWebData().wechat_visitor" alt="" />
                        </div>
                    </div>
                </div>
                <div class="hb1Right">
                    <img
                        :src="getAdvData(13).children[0].children[0].image"
                        alt=""
                        class="hrImg wow animate__animated animate__slideInUp"
                    />
                </div>
            </div>
        </div>
        <div class="hbBox2">
            <div class="hb2Top">
                <p class="ht1">我们的优势</p>
                <p class="ht2">针对海外客户群体，为您解决98%以上的行业痛点问题</p>
            </div>
            <div class="hb2Box1">
                <div class="h2bLeft wow animate__animated animate__slideInUp">
                    <img src="http://iv.vu818.com/img/hc1321.png" alt="" />
                </div>
                <div class="h2bRight">
                    <div class="h2rBoxs up" v-for="item in 2" :key="item">
                        <div class="h2rBox">
                            <div class="hr1">
                                <img src="http://iv.vu818.com/img/hc1322.png" alt="" />
                            </div>
                            <div class="hr2">
                                <p class="hr21">响应式网站</p>
                                <p class="hr22">响应式网站、自适配电脑，平板，手机</p>
                            </div>
                        </div>
                    </div>
                    <div class="h2rBtn">
                        <img src="http://iv.vu818.com/img/xma14.png" alt="" class="hl4Img" />
                        <p class="hl4p">免费索取方案 ></p>
                        <div class="h4Code">
                            <img :src="getWebData().wechat_visitor" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb2Box2">
                <div class="h2bRight">
                    <div class="h2rBoxs up" v-for="item in 2" :key="item">
                        <div class="h2rBox">
                            <div class="hr1">
                                <img src="http://iv.vu818.com/img/hc1332.png" alt="" />
                            </div>
                            <div class="hr2">
                                <p class="hr21">适合外国人浏览的网站设计风格</p>
                                <p class="hr22">我们会根据您的客户群体，专属设计符合您客户浏览习惯的外贸网站布局及风格</p>
                            </div>
                        </div>
                    </div>
                    <div class="h2rBtn">
                        <img src="http://iv.vu818.com/img/xma14.png" alt="" class="hl4Img" />
                        <p class="hl4p">立即咨询 ></p>
                        <div class="h4Code">
                            <img :src="getWebData().wechat_visitor" alt="" />
                        </div>
                    </div>
                </div>
                <div class="h2bLeft wow animate__animated animate__slideInUp">
                    <img src="http://iv.vu818.com/img/hc1331.png" alt="" />
                </div>
            </div>
            <div class="hb2Box3">
                <div class="h2bLeft wow animate__animated animate__slideInUp">
                    <img src="http://iv.vu818.com/img/hc1341.png" alt="" />
                </div>
                <div class="h2bRight">
                    <div class="h2rBoxs up" v-for="item in 3" :key="item">
                        <div class="h2rBox">
                            <div class="hr1">
                                <img src="http://iv.vu818.com/img/hc1342.png" alt="" />
                            </div>
                            <div class="hr2">
                                <p class="hr21">多语言满足海外用户</p>
                                <p class="hr22">多语言自由切换，满足不同海外用户需求，符合海外搜索引擎收录习惯</p>
                            </div>
                        </div>
                    </div>
                    <div class="h2rBtn">
                        <img src="http://iv.vu818.com/img/xma14.png" alt="" class="hl4Img" />
                        <p class="hl4p">立即咨询 ></p>
                        <div class="h4Code">
                            <img :src="getWebData().wechat_visitor" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HsCom8></HsCom8>
        <div class="hbBox3">
            <div class="hc7Box">
                <!-- 标题 -->
                <div class="hc7Title">
                    <p class="hc7Big">案例展示</p>
                    <p class="hc7Small">CASE SHOWS</p>
                </div>
                <!-- 图片列表 -->
                <div class="hc7Imgs">
                    <ul>
                        <li
                            class="hc7Li wow animate__animated animate__slideInUp"
                            v-for="item in list"
                            :key="item.id"
                            @click.prevent="goDetail(item)"
                        >
                            <img v-lazy="item.little_image" class="hc7Img" />
                            <div class="h7Mask" v-if="item.qrcode || item.logo">
                                <img v-if="item.qrcode" v-lazy="item.qrcode" class="h7mCode" />
                                <img v-else v-lazy="item.logo" class="h7mCode" />
                                <!-- <p class="h7m1">长虹国际</p> -->
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="hc7Btn" @click="goMore">更多案例></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import HsCom8 from "./HsCom8.vue";

export default {
    name: "HsContent13",
    inject: ["getWebData", "getAdvData"],
    components: {
        HsCom8,
    },
    data() {
        return {
            list: [],
        };
    },
    async mounted() {
        await this.getData();
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {
        goMore() {
            this.$router.push("/case");
        },
        async getData() {
            const { data } = await axios.get("api/project/foreignTrade");
            this.list = data;
            console.log(data);
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.up {
    transform: translateY(0px);
    transition: 0.5s;

    &:hover {
        transition: 0.5s;
        transform: translateY(-20px);
    }
}
.hc13Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        width: 100%;
        height: 616px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        .h1Box {
            max-width: 1280px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .hb1Left {
                width: 500px;
                height: 446px;
                margin-top: 100px;
                float: left;
                .hl1 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    color: white;
                    font-size: 50px;
                    font-weight: 900;
                }
                .hl2 {
                    width: 100%;
                    height: 68px;
                    line-height: 34px;
                    font-size: 24px;
                    color: white;
                    margin-top: 20px;
                }
                .hl3 {
                    width: 100%;
                    height: 88px;
                    margin-top: 80px;
                    .hl3b {
                        width: 185px;
                        height: 88px;
                        float: left;
                        .h1t {
                            width: 100%;
                            height: 60px;
                            line-height: 60px;
                            font-size: 60px;
                            color: #ffc52f;
                        }
                        .h1b {
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            font-size: 18px;
                            color: #f2f7ff;
                        }
                    }
                    .hl3b:nth-child(2) {
                        margin-left: 40px;
                    }
                }
                .hl4 {
                    width: 190px;
                    height: 60px;
                    line-height: 60px;
                    color: white;
                    font-size: 20px;
                    background-color: #0068f6;
                    border-radius: 25px;
                    margin-top: 70px;
                    position: relative;
                    cursor: pointer;
                    .hl4p {
                        float: left;
                        margin-left: 10px;
                        color: white;
                    }
                    .hl4Img {
                        width: 26px;
                        height: 26px;
                        display: block;
                        float: left;
                        margin-top: 16px;
                        margin-left: 20px;
                    }
                    .h4Code {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        top: -110px;
                        left: 50px;
                        border-radius: 10px;
                        overflow: hidden;
                        opacity: 0;
                        transition: 0.5s;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .hl4:hover {
                    .h4Code {
                        opacity: 1;
                    }
                }
            }
            .hb1Right {
                width: 700px;
                height: 433px;
                float: left;
                margin-top: 100px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 2312px;
        overflow: hidden;
        .hb2Top {
            width: 100%;
            height: 83px;
            margin-top: 50px;
            text-align: center;
            .ht1 {
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 40px;
            }
            .ht2 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                margin-top: 10px;
                font-size: 18px;
                color: gray;
            }
        }
        .hb2Box1 {
            max-width: 1280px;
            height: 560px;
            margin: 0 auto;
            margin-top: 100px;
            .h2bLeft {
                width: 655px;
                height: 580px;
                float: left;
                img {
                    width: 585px;
                    height: 545px;
                }
            }
            .h2bRight {
                width: 550px;
                height: 469px;
                float: left;
                .h2rBoxs {
                    width: 100%;
                    height: 160px;
                    border-radius: 10px;
                    background-color: #f8fafb;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    overflow: hidden;
                    .h2rBox {
                        width: 90%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 3%;
                        .hr1 {
                            width: 48px;
                            height: 42px;
                            float: left;
                            margin-top: 20px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .hr2 {
                            width: 410px;
                            height: 73px;
                            float: left;
                            margin-top: 20px;
                            margin-left: 20px;
                            .hr21 {
                                width: 100%;
                                height: 34px;
                                line-height: 34px;
                                font-size: 25px;
                            }
                            .hr22 {
                                width: 100%;
                                height: 26px;
                                line-height: 26px;
                                margin-top: 13px;
                                color: gray;
                            }
                        }
                    }
                }
                .h2rBoxs:nth-child(2) {
                    margin-top: 30px;
                }
                .h2rBtn {
                    width: 230px;
                    height: 60px;
                    line-height: 60px;
                    font-size: 20px;
                    color: white;
                    border-radius: 30px;
                    background-color: #0068f6;
                    margin: 0 auto;
                    margin-top: 40px;
                    position: relative;
                    cursor: pointer;
                    .hl4p {
                        float: left;
                        margin-left: 10px;
                    }
                    .hl4Img {
                        width: 26px;
                        height: 26px;
                        display: block;
                        float: left;
                        margin-top: 16px;
                        margin-left: 20px;
                    }
                    .h4Code {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        top: -110px;
                        left: 50px;
                        border-radius: 10px;
                        overflow: hidden;
                        opacity: 0;
                        transition: 0.5s;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .h2rBtn:hover {
                    .h4Code {
                        opacity: 1;
                    }
                }
            }
        }
        .hb2Box2 {
            max-width: 1280px;
            height: 560px;
            margin: 0 auto;
            margin-top: 100px;
            .h2bRight {
                width: 550px;
                height: 469px;
                float: left;
                .h2rBoxs {
                    width: 100%;
                    height: 160px;
                    border-radius: 10px;
                    background-color: #f8fafb;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    overflow: hidden;
                    .h2rBox {
                        width: 90%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 3%;
                        .hr1 {
                            width: 48px;
                            height: 42px;
                            float: left;
                            margin-top: 20px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .hr2 {
                            width: 410px;
                            height: 73px;
                            float: left;
                            margin-top: 20px;
                            margin-left: 20px;
                            .hr21 {
                                width: 100%;
                                height: 34px;
                                line-height: 34px;
                                font-size: 25px;
                            }
                            .hr22 {
                                width: 100%;
                                height: 26px;
                                line-height: 26px;
                                margin-top: 13px;
                                color: gray;
                            }
                        }
                    }
                }
                .h2rBoxs:nth-child(2) {
                    margin-top: 30px;
                }
                .h2rBtn {
                    width: 230px;
                    height: 60px;
                    line-height: 60px;
                    font-size: 20px;
                    color: white;
                    border-radius: 30px;
                    background-color: #0068f6;
                    margin: 0 auto;
                    margin-top: 40px;
                    cursor: pointer;
                    position: relative;
                    .hl4p {
                        float: left;
                        margin-left: 10px;
                    }
                    .hl4Img {
                        width: 26px;
                        height: 26px;
                        display: block;
                        float: left;
                        margin-top: 16px;
                        margin-left: 50px;
                    }
                    .h4Code {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        top: -110px;
                        left: 50px;
                        border-radius: 10px;
                        overflow: hidden;
                        opacity: 0;
                        transition: 0.5s;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .h2rBtn:hover {
                    .h4Code {
                        opacity: 1;
                    }
                }
            }
            .h2bLeft {
                width: 655px;
                height: 580px;
                float: left;
                img {
                    width: 585px;
                    height: 545px;
                    float: right;
                }
            }
        }
        .hb2Box3 {
            max-width: 1280px;
            height: 803px;
            margin: 0 auto;
            margin-top: 100px;
            .h2bLeft {
                width: 655px;
                height: 580px;
                float: left;
                img {
                    width: 585px;
                    height: 545px;
                }
            }
            .h2bRight {
                width: 550px;
                height: 469px;
                float: left;
                .h2rBoxs {
                    width: 100%;
                    height: 160px;
                    border-radius: 10px;
                    background-color: #f8fafb;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    overflow: hidden;
                    .h2rBox {
                        width: 90%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 3%;
                        .hr1 {
                            width: 48px;
                            height: 42px;
                            float: left;
                            margin-top: 20px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .hr2 {
                            width: 410px;
                            height: 73px;
                            float: left;
                            margin-top: 20px;
                            margin-left: 20px;
                            .hr21 {
                                width: 100%;
                                height: 34px;
                                line-height: 34px;
                                font-size: 25px;
                            }
                            .hr22 {
                                width: 100%;
                                height: 26px;
                                line-height: 26px;
                                margin-top: 13px;
                                color: gray;
                            }
                        }
                    }
                }
                .h2rBoxs:nth-child(2),
                .h2rBoxs:nth-child(3) {
                    margin-top: 30px;
                }
                .h2rBtn {
                    width: 230px;
                    height: 60px;
                    line-height: 60px;
                    font-size: 20px;
                    color: white;
                    border-radius: 30px;
                    background-color: #0068f6;
                    margin: 0 auto;
                    margin-top: 40px;
                    cursor: pointer;
                    position: relative;
                    .h4Code {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        top: -110px;
                        left: 50px;
                        border-radius: 10px;
                        overflow: hidden;
                        opacity: 0;
                        transition: 0.5s;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .hl4p {
                        float: left;
                        margin-left: 10px;
                    }
                    .hl4Img {
                        width: 26px;
                        height: 26px;
                        display: block;
                        float: left;
                        margin-top: 16px;
                        margin-left: 50px;
                    }
                }
                .h2rBtn:hover {
                    .h4Code {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 900px;
        margin-top: 50px;
        overflow: hidden;
        .hc7Box {
            max-width: 1280px;
            height: 100%;
            margin: 0 auto;
            // 文字
            .hc7Title {
                width: 50%;
                height: 100px;
                margin: 0 auto;
                text-align: center;
                .hc7Big {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 50px;
                    color: #0095e2;
                }
                .hc7Small {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 30px;
                    margin-top: 20px;
                    color: #878787;
                }
            }
            // 图片列表
            .hc7Imgs {
                width: 100%;
                height: 658px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .hc7Li {
                        width: 31%;
                        height: 288px;
                        border-radius: 20px;
                        overflow: hidden;
                        position: relative;
                        .hc7Img {
                            width: 100%;
                            height: 100%;
                            transition: 0.5s;
                            object-fit: cover;
                        }
                        .h7Mask {
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.5);
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: 0.5s;
                            opacity: 0;
                            .h7mCode {
                                width: 120px;
                                height: 120px;
                                border-radius: 5px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                // display: block;
                                // margin: 0 auto;
                                // margin-top: 60px;
                                object-fit: contain;
                            }
                            .h7m1 {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                color: white;
                                margin-top: 20px;
                            }
                        }
                    }
                    .hc7Li:hover {
                        .hc7Img {
                            transform: scale(1.1);
                        }
                        .h7Mask {
                            opacity: 1;
                        }
                    }
                }
            }
            .hc7Btn {
                width: 190px;
                height: 60px;
                margin: 0 auto;
                border-radius: 30px;
                line-height: 60px;
                text-align: center;
                color: #0068f6;
                font-size: 20px;
                box-shadow: 1px 1px 10px 1px gainsboro;
                cursor: pointer;
            }
        }
    }
}
</style>
